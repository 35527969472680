import React, {useEffect, useState} from 'react'
import './mappings.css'
import Tabs from './Subcomponents/Tabs'
import ShippingMapping from './Subcomponents/ShippingMapping/ShippingMapping'
import PaymentMapping from './Subcomponents/PaymentMapping/PaymentMapping'
import MultiLocationMapping from './Subcomponents/MultiLocationMapping/MultiLocationMapping'
import ProductAttributeMapping from './Subcomponents/ProductAttributeMapping/ProductAttributeMapping'
import CustomerAttributeMapping from './Subcomponents/CustomerAttributeMapping/CustomerAttributeMapping'

const Mappings = (props) => {
    const [tabType, setTabType] = useState()

    useEffect(() => {
        if(props.instance.isShippingMethodMappingEnable && props.instance.isPaymentMethodMappingEnable){
            setTabType("ShippingMapping")
        }else if(!props.instance.isShippingMethodMappingEnable && props.instance.isPaymentMethodMappingEnable){
            setTabType("PaymentMethod")
        }else if(!props.instance.isShippingMethodMappingEnable && !props.instance.isPaymentMethodMappingEnable && props.instance.isMultiWarehouseAttributeMappingEnable){
            setTabType("MultiLocation")
        }
    },[]);
    const activeTab = (activeTab) =>{
        setTabType(activeTab)        
    }
    return (
        <>
            <Tabs 
                getActiveTab = {activeTab} 
                instance={props.instance}
            />
            <div className='col-md-9 col-xl-9'>
                <div className='card mndesc'>
                    {
                        tabType == "ShippingMapping" ? 
                            <ShippingMapping
                                connectorId={props.connectorId}
                                instancesId={props.instancesId}
                            />
                        :
                        tabType == "PaymentMethod" ? 
                            <PaymentMapping 
                                connectorId={props.connectorId}
                                instancesId={props.instancesId}
                            />
                        :  
                        tabType == "MultiLocation" ? 
                            <MultiLocationMapping 
                                connectorId={props.connectorId}
                                instancesId={props.instancesId}
                                sourceId={props.sourceId}
                                targetId={props.targetId}
                                sourceEndpointId={props.sourceEndpointId}
                                targetEndpointId={props.targetEndpointId}
                                id={props.id}
                            />
                        :
                        tabType == "ProductAttributeMapping" ? 
                            <ProductAttributeMapping
                                connectorId={props.connectorId}
                                instancesId={props.instancesId}
                                sourceId={props.sourceId}
                                targetId={props.targetId}
                                sourceEndpointId={props.sourceEndpointId}
                                targetEndpointId={props.targetEndpointId}
                                sourceShortName={props.sourceShortName}
                                targetShortName={props.targetShortName}
                                id={props.id}
                            />
                        :tabType == "CustomerAttributeMapping" ?
                            <CustomerAttributeMapping
                                connectorId={props.connectorId}
                                instancesId={props.instancesId}
                                sourceId={props.sourceId}
                                targetId={props.targetId}
                                sourceEndpointId={props.sourceEndpointId}
                                targetEndpointId={props.targetEndpointId}
                                sourceShortName={props.sourceShortName}
                                targetShortName={props.targetShortName}
                                id={props.id}
                            />
                        :null
                    }
                </div>
            </div>
        </>
    )
}

export default Mappings