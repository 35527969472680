import React, { useState } from 'react'
import AddNewMappingSource from './SourceToTargetMappings/AddNewMapping/AddNewMapping'
import AddNewMappingTarget from './TargetToSourceMappings/AddNewMapping/AddNewMapping'
import Toggle from './Toggle'
import ToggleImg from "../../../ViewDetails/images/toggleSummary.svg"
import SourceToTargetMappings from './SourceToTargetMappings/SourceToTargetMappings'
import TargetToSourceMappings from './TargetToSourceMappings/TargetToSourceMappings'

const CustomerAttributeMapping = (props) => {
    const newMappingData = []
    const [newMapping, setNewMapping] = useState(newMappingData)
    const [sourcetoTarget, setSourcetoTarget] = useState(true)
    const [targettoSource, setTargettoSource] = useState(false)

    const flipPanel = (event) =>{
        setSourcetoTarget(!sourcetoTarget)
        setTargettoSource(!targettoSource)
    }
    
    return (
        <>
            <div className="card-header p-10">
                <div className='row'>
                    <div className='col'>
                        {sourcetoTarget ? props.sourceShortName : props.targetShortName}
                    </div>
                    <div className='col'>
                        <div className='selectFlip' onClick={flipPanel}>
                        </div>
                        <Toggle ToggleImg={ToggleImg} />
                    </div>
                    <div className='col'>
                        {targettoSource ? props.sourceShortName : props.targetShortName}
                    </div>
                    <div className='col'>
                        {
                            sourcetoTarget ? 
                            <AddNewMappingSource 
                                connectorId ={props.connectorId}
                                instancesId={props.instancesId}
                                sourceShortName={props.sourceShortName}
                                targetShortName={props.targetShortName}
                            />
                            :   
                            <AddNewMappingTarget 
                                connectorId ={props.connectorId}
                                instancesId={props.instancesId}
                                sourceShortName={props.sourceShortName}
                                targetShortName={props.targetShortName}
                            />
                        }
                    </div>
                </div>
            </div>
            <div className='card-body p-10'>
                <div className='mappingFormContainer'>
                    {
                        sourcetoTarget ? 
                            <SourceToTargetMappings
                                connectorId ={props.connectorId}
                                instancesId={props.instancesId}
                                newlyAddedMapping={newMapping}
                            /> 
                        : 
                        <TargetToSourceMappings 
                            connectorId ={props.connectorId}
                            instancesId={props.instancesId}
                            newlyAddedMapping={newMapping}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default CustomerAttributeMapping




