import React,{useState} from 'react'
import API from '../../../API/API'
import $ from 'jquery'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {Link} from 'react-router-dom'
import inputCalender from '../images/input_calender.svg'
const BreadCrumb = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const isFutureDate = (date) => {
        // const today = new Date();
        // return date < today;

        const today = new Date();
        // Set the time of today to the beginning of the day to ensure accurate comparison
        today.setHours(0, 0, 0, 0);
        // Set the time of the given date to the beginning of the day
        const dateWithoutTime = new Date(date);
        dateWithoutTime.setHours(0, 0, 0, 0);
        // Compare the date without time to today's date without time
        return dateWithoutTime < today;
    };
    const isFutureDate1 = (date) => {
        const today = new Date();
        return date >= today;
    };

    const handleStartDateChange = (date) => {
        setStartDate(date);
        // If the selected start date is after the end date, reset the end date
        if (date > endDate) {
          setEndDate(null);
        }
      };
    
      const handleEndDateChange = (date) => {
        setEndDate(date);
        // If the selected end date is before the start date, reset the start date
        if (date < startDate) {
          setStartDate(null);
        }
      };

    const applyDate = () =>{
        if(startDate === null && endDate === null){
            $('#startDatePicker').attr('placeholder','Please pick from date');
            $('#startDatePicker').addClass("errorNew")
            $('#endDatePicker').attr('placeholder','Please pick to date');
            $('#endDatePicker').addClass("errorNew")
        }else if(startDate === null){
            $('#startDatePicker').attr('placeholder','Please pick from date');
            $('#startDatePicker').addClass("errorNew")
        }else if(endDate === null){
            $('#endDatePicker').attr('placeholder','Please pick to date');
            $('#endDatePicker').addClass("errorNew")
        }else{
            var newstartDate = new Date(startDate),
                newstartDatemnth = ("0" + (newstartDate.getMonth() + 1)).slice(-2),
                newstartDateday = ("0" + newstartDate.getDate()).slice(-2);

            var convertedendDate = new Date(endDate),
                newendDatemnth = ("0" + (convertedendDate.getMonth() + 1)).slice(-2),
                newendDateday = ("0" + convertedendDate.getDate()).slice(-2);

            var convertedStartDate = [newstartDate.getFullYear(), newstartDatemnth, newstartDateday].join("-")
            var convertedEndDate = [convertedendDate.getFullYear(), newendDatemnth, newendDateday].join("-")

            
            var response = window.localStorage.getItem("accessToken");
            const headers = {
                "authorization": `Bearer ${response}`,
                "content-type": "application/json"
            };
            const bodySource = {
                "subscriptionInstanceId": props.instanceId,
                "sourceEndpointId": props.sourceId,
                "requestType": "Source",
                "createdTime": {
                    "fromTime": null,
                    "toTime": null
                },
                "updatedTime": {
                    "fromTime":  convertedStartDate,
                    "toTime": convertedEndDate
                },
            }
            const bodyTarget = {
                "subscriptionInstanceId": props.instanceId,
                "sourceEndpointId": props.targetId,
                "requestType": "Target",
                "createdTime": {
                    "fromTime": null,
                    "toTime": null
                },
                "updatedTime": {
                    "fromTime":  convertedStartDate,
                    "toTime": convertedEndDate
                },
            }

            API.post("/Client/connections/report/summary", bodySource, {headers})
            .then(response=>{
                API.post("/Client/connections/report/summary", bodyTarget, {headers})
                .then(response1=>{
                    props.filterData({
                        instancesId: props.instanceId,
                        sourceEntity:response.data,
                        sourceId: props.sourceId,
                        sourceShortName: props.sourceShortName,
                        targetEntity: response1.data,
                        targetId: props.targetId,
                        targetShortName: props.targetShortName
                    })
                })
            })
        }
    }

    return (
        <div className="row">
            <div className="col-12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <div className="float-left page-title-left">
                        <ol className="breadcrumb m-0">
                            <li className="breadcrumb-item"><Link to="/Dashboard">Dashboard</Link></li>
                            <li className="breadcrumb-item active">Entities</li>
                        </ol>
                    </div>
                    <div className="float-right page-title-right positionrelative topnine detailsCal">
                        <div className="form-inline form-group">
                            <div className="input-group mr-3">
                                <label className="summaryDateFrom"> From </label>
                                <DatePicker
                                    id={"startDatePicker"}
                                    className="form-control"
                                    placeholderText={"Pick the start date"}
                                    selected={startDate}
                                    startDate={startDate}
                                    endDate={endDate} 
                                    format={'yyyy-MM-dd'}
                                    //onChange={date => setStartDate(date)}
                                    onChange={handleStartDateChange}
                                    filterDate={isFutureDate}
                                />
                                <span className="calenderIcon"> <img src={inputCalender} /> </span>
                                <label className="summaryDateFrom"> To </label>
                                <DatePicker
                                    id={"endDatePicker"}
                                    className="form-control"
                                    placeholderText={"Pick the end date"}
                                    selected={endDate}
                                    startDate={startDate}
                                    endDate={endDate}
                                    minDate={startDate}
                                    format={'yyyy-MM-dd'}
                                    //onChange={date => setEndDate(date)}
                                    onChange={handleEndDateChange}
                                    filterDate={isFutureDate1}
                                />
                                <span className="calenderIcon"> <img src={inputCalender} /> </span>
                            </div>
                            <button className="btn btn-primary mb-2" onClick={applyDate}> Apply </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BreadCrumb