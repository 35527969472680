import React,{useEffect, useState} from 'react'
import './addNewMapping.css'
import API from '../../../../../../API/API';
import $ from 'jquery'
import SlidingPane from "react-sliding-pane";
import MappingList from './MappingList/MappingList';

function AddNewMapping(props) {
    const [isLoading, setIsLoading] = useState(false);
    const [allUpdatedMappings, setAllUpdatedMappings] = useState()
    const [state, setState] = useState({
        isPaneOpen: false,
    });

    useEffect(() => {

    },[])

    const submitSelectedMappings = () =>{
        var allMapping = []
        $("#paymentMappingTable tr").each(function(){
            var mappingTable = {
                "ecommerceMethod": $(this).find("td:nth-child(2)").attr("headers"),
                "erpMethod": $(this).find("td:nth-child(1)").attr("headers"),
                "isEcommerceDefault": $(this).find("td:nth-child(3)").attr("headers") === "true" ? true : false,
                "isErpDefault": $(this).find("td:nth-child(4)").attr("headers") === "true" ? true : false
            }
            allMapping.push(mappingTable)
        })
        setIsLoading(true);
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        const body = allMapping
        API.post(`/Connection/${props.connectorId}/instance/${props.instancesId}/payment-mappings`, body, { headers })
        .then((response) => {
            if(response.status === 200){
                setIsLoading(false);
            }
        })
    }

    const addNewMapping = () =>{
        setState({ isPaneOpen: true })
    }

    const updateMappings = ()=>{
        setIsLoading(true);
        var allMapping = []
        $("#newAttributeMappingTableNewProduct tbody tr").each(function(){
            var mappingTable = {
                "erpAttributeName": $(this).find("td:nth-child(1)").attr("headers"),
                "erpAttributeDataType": $(this).find("td:nth-child(2)").attr("headers"),
                "erpIsRequired": $(this).find("td:nth-child(3)").attr("headers") === "true" ? true : false,
                "erpAttributeSize": $(this).find("td:nth-child(4)").attr("headers"),
                "ecommerceAttributeName": $(this).find("td:nth-child(5)").attr("headers"),
                "ecommerceAttributeDataType": $(this).find("td:nth-child(6)").attr("headers"),
                "ecommerceIsRequired":  $(this).find("td:nth-child(7)").attr("headers") === "true" ? true : false,
                "ecommerceAttributeSize": $(this).find("td:nth-child(8)").attr("headers"),
                "ecommerceAttributeType": $(this).find("td:nth-child(9)").attr("headers"),
                "canUpdate": true,
                "canDelete": true,
            }
            allMapping.push(mappingTable)
        })
        var response =window.localStorage.getItem("accessToken") ;
        const headers={
            "authorization": `Bearer ${response}`,
            "content-type" : "application/json"
        };
        const body = {"sourceMappedSchemaList": allMapping}
        //Connection/{ConnectionId}/instance/{InstanceId}/save-customer-mappings/{RequestType}
        API.post(`/Connection/${props.connectorId}/instance/${props.instancesId}/save-customer-mappings/Source`, body, { headers })
        .then((response) => {
            if(response.status === 200){
                setIsLoading(false);
                $(".addNewMappingSource").removeClass('d-none')
                $(".updateMappings").addClass('d-none')
            }
        })
    }

    return (
        <>
            {isLoading ?
                <div className="spinner-container spinner-container12">
                    <div className="loading-spinner"></div>
                </div> : null
            }

            <button className='submitButton addNewMappingSource' onClick={addNewMapping}>
                Add New Mappings
            </button>
            <button className='submitButton updateMappings d-none' onClick={updateMappings}>
                Update Mappings
            </button>
            <SlidingPane overlayClassName="some-custom-overlay-class productAttributeMapping" isOpen={state.isPaneOpen} onRequestClose={() => {setState({ isPaneOpen: false });}} title={`Add New Product Attribute Mappings`} subtitle={`${props.sourceShortName} to ${props.targetShortName}`}>
                <MappingList
                    connectorId ={props.connectorId}
                    instancesId={props.instancesId}
                />
            </SlidingPane>
        </>
        
    )
}

export default AddNewMapping
